import { useTheme } from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { RenderAs } from '@retorio/react-utils'

import {
  defaultItemMarginX,
  defaultItemMarginY,
  defaultItemSize,
} from './SidebarNavItem.defaults'
import { SelectedAndHeightProp } from './SidebarNavItem.types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const itemCss = (props: SelectedAndHeightProp & { theme: any }) => css`
  width: calc(100% - ${defaultItemMarginX} * 2);
  min-height: ${defaultItemSize};
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;

  margin: ${defaultItemMarginY} ${defaultItemMarginX};
  border-radius: ${props.theme.radii.base};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: 'transparent';
  color: ${props.theme.colors['secondary-light']};
  outline: none;

  transition: background-color
    ${`${props.theme.transition.duration.faster} ${props.theme.transition.easing['ease-in-out']}`};

  &.active:not(.disabled):not([disabled]),
  &:hover:not(.disabled):not([disabled]) {
    background-color: ${props.theme.colors.secondary};
    color: ${props.theme.colors['primary-dark']};
  }

  &[disabled],
  &.disabled {
    cursor: default;
    color: ${props.theme.colors.white};
    margin-left: 0.25rem;
  }
`

const Item = styled(RenderAs)`
  ${itemCss}
`

const Text = styled.span`
  margin-left: 0.42rem;
`

export const SidebarNavItem = ({ children, ...props }) => {
  const theme = useTheme()

  return (
    <Item theme={theme} height="48px" selected={false} role="menuitem" {...props}>
      <Text>{children}</Text>
    </Item>
  )
}
