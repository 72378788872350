import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors, Spinner } from '@retorio/ui'
import { Fragment, ReactNode } from 'react'
import { BiBell } from 'react-icons/bi'
import { BsDot, BsInbox } from 'react-icons/bs'

import { FormattedMessage } from '../../common/FormattedMessage'
import { UserNotificationsType } from '../../types/userNotifications'
import NotificationMenuItem from './NotificationMenuItem'

type SharedUserNotificationsProps = {
  handleOnClick: (notification: UserNotificationsType) => void
  handleMarkAllAsRead: () => void
  handleEmptyBox: () => void
  unreadNotificationCount: number | undefined
  isInitializing?: boolean
  notificationsError: unknown
  notificationsData: Array<UserNotificationsType> | undefined
  mutateNotifications: () => void

  renderShareProgramNotification?: (notification: UserNotificationsType) => ReactNode
  isLightMode?: boolean
}

export const SharedUserNotifications = ({
  handleOnClick,
  handleMarkAllAsRead,
  handleEmptyBox,
  unreadNotificationCount,
  isInitializing,
  notificationsError,
  notificationsData,
  mutateNotifications,
  renderShareProgramNotification,
  isLightMode,
}: SharedUserNotificationsProps) => (
  <Menu>
    <MenuButton
      as={IconButton}
      aria-label="Notification menu"
      bg="transparent !important"
      color={isLightMode ? 'white' : 'primary-dark'}
      size="md"
      icon={
        <div style={{ position: 'relative', display: 'grid', alignItems: 'end' }}>
          {unreadNotificationCount !== 0 && !isInitializing && (
            <Icon
              boxSize={6}
              position="absolute"
              ml="8px"
              mb="4px"
              as={BsDot}
              color="red"
            />
          )}
          <BiBell size="20px" />
        </div>
      }
    />
    <MenuList zIndex={100} w={['380px', '380px', '460px']} boxShadow="dark-lg">
      <Box
        mx={6}
        mb={2}
        borderBottom="1px"
        borderColor="gray.300"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack py={4} spacing={1}>
          <Text fontWeight="500" fontSize="24px">
            <FormattedMessage id="notification.inbox" />
          </Text>
          {unreadNotificationCount !== 0 && !notificationsError && !isInitializing && (
            <Text fontWeight="400" fontSize="18px" color="blue.500">
              ({unreadNotificationCount})
            </Text>
          )}
        </HStack>
      </Box>
      {isInitializing && (
        <VStack p={2} mx={2} my={8} alignItems="center">
          <Center w="full" h="full">
            <Spinner />
          </Center>
        </VStack>
      )}

      {!!notificationsError && (
        <VStack p={2} mx={2} my={8} alignItems="center">
          <Text color="red.500">
            <FormattedMessage id="notification.load.error" />
          </Text>
          <Button
            size="sm"
            onClick={mutateNotifications}
            colorScheme="blue"
            variant="outline"
          >
            <FormattedMessage id="notification.retry" />
          </Button>
        </VStack>
      )}
      {!isInitializing && !notificationsError && notificationsData?.length === 0 && (
        <VStack p={2} mx={2} my={8} alignItems="center">
          <Icon as={BsInbox} color={colors.gray[400]} boxSize="1.25em" />
          <Text textAlign="center" color={colors.gray[400]}>
            <FormattedMessage
              id="notification.empty.list"
              values={{ lineBreak: <br /> }}
            />
          </Text>
        </VStack>
      )}

      {!isInitializing && !notificationsError && notificationsData?.length !== 0 && (
        <Fragment>
          <HStack mx={6} my={4}>
            <Button
              size="sm"
              onClick={handleMarkAllAsRead}
              colorScheme="blue"
              variant="outline"
              flex={1}
            >
              <FormattedMessage id="notification.mark.all.read" />
            </Button>
            <Button
              size="sm"
              onClick={handleEmptyBox}
              colorScheme="blue"
              variant="outline"
              flex={1}
            >
              <FormattedMessage id="notification.delete.all" />
            </Button>
          </HStack>
          <VStack maxH="400px" overflowY="auto">
            {notificationsData?.map(notification => (
              <NotificationMenuItem
                notification={notification}
                handleOnClick={handleOnClick}
                key={notification.extId}
                renderShareProgramNotification={renderShareProgramNotification}
              />
            ))}
          </VStack>
        </Fragment>
      )}
    </MenuList>
  </Menu>
)
