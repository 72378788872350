import { Box, Hide, HStack, Icon, Select, Show, Text, VStack } from '@chakra-ui/react'
import { colors } from '@retorio/ui'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState } from 'react'
import { isFirefox, isSafari } from 'react-device-detect'
import { HiCheckCircle } from 'react-icons/hi'

import { FormattedMessage } from './FormattedMessage'

type DeviceSelectorProps = {
  deviceType: 'video' | 'audio'
  devices: Array<MediaDeviceInfo>
  selectedDevice: string | undefined
  setSelectedDevice: (deviceId: string) => void
}

const DeviceSelector = ({
  deviceType,
  devices,
  selectedDevice,
  setSelectedDevice,
}: DeviceSelectorProps) => (
  <VStack alignItems="flex-start" w="100%">
    <HStack gap={1}>
      <Icon as={HiCheckCircle} color={colors.blue[500]} boxSize="18px" />
      <Text fontSize="16px" fontWeight={600}>
        <FormattedMessage
          id={`route.terms.${deviceType === 'video' ? 'camera' : 'microphone'}`}
        />
      </Text>
    </HStack>
    <Hide below="md">
      <Select
        borderRadius="md"
        minH="36px"
        minW="280px"
        bgColor={colors.gray[50]}
        border={0}
        value={selectedDevice ?? ''}
        onChange={option => setSelectedDevice(option.target.value)}
      >
        {devices.map(device => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </Select>
    </Hide>
    <Show below="md">
      <Box bgColor={colors.gray[50]} borderRadius="md" p={2} minW="300px" w="100%">
        <Text fontSize="14px" fontWeight={400}>
          {selectedDevice
            ? devices.find(device => device.deviceId === selectedDevice)?.label
            : `Select ${deviceType === 'video' ? 'camera' : 'microphone'}`}
        </Text>
      </Box>
    </Show>
  </VStack>
)

type MediaDeviceInfo = {
  deviceId: string
  kind: string
  label: string
}
type UserMediaCheckProps = {
  setUserMediaDevices: (
    selectedVideoDevice: string | undefined,
    selectedAudioDevice: string | undefined
  ) => void
  permissionGranted: boolean
}

export const UserMediaCheck = ({
  setUserMediaDevices,
  permissionGranted,
}: UserMediaCheckProps) => {
  const [videoDevices, setVideoDevices] = useState<Array<MediaDeviceInfo>>([])
  const [audioDevices, setAudioDevices] = useState<Array<MediaDeviceInfo>>([])
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string | undefined>()
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string | undefined>()
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | null>(null)

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const video = devices.filter(device => device.kind === 'videoinput')
        const audio = devices.filter(device => device.kind === 'audioinput')
        const speaker = devices.filter(device => device.kind === 'audiooutput')

        setSelectedSpeaker(speaker[0]?.label || '')
        setVideoDevices(video)
        setAudioDevices(audio)
        setSelectedVideoDevice(video[0]?.deviceId)
        setSelectedAudioDevice(audio[0]?.deviceId)
      })
      .catch(error => {
        console.error('Error enumerating devices:', error)
      })
  }, [permissionGranted])

  useEffect(() => {
    setUserMediaDevices(selectedVideoDevice, selectedAudioDevice)
  }, [selectedAudioDevice, selectedVideoDevice, setUserMediaDevices])

  return (
    <VStack alignItems="flex-start" gap={4}>
      <DeviceSelector
        deviceType="video"
        devices={videoDevices}
        selectedDevice={selectedVideoDevice}
        setSelectedDevice={setSelectedVideoDevice}
      />

      <DeviceSelector
        deviceType="audio"
        devices={audioDevices}
        selectedDevice={selectedAudioDevice}
        setSelectedDevice={setSelectedAudioDevice}
      />
      {!(isSafari || isFirefox) && (
        <Fragment>
          <HStack gap={1}>
            <Icon as={HiCheckCircle} color={colors.blue[500]} boxSize="18px" />
            <Text fontSize="16px" fontWeight={600}>
              <FormattedMessage id="route.terms.speaker" />
            </Text>
          </HStack>
          <Box
            bgColor={colors.gray[50]}
            minW="280px"
            w="100%"
            borderRadius="md"
            minH="60px"
            p={2}
          >
            <Text fontSize="14px" fontWeight={400}>
              {selectedSpeaker}
            </Text>
          </Box>
        </Fragment>
      )}
    </VStack>
  )
}
