import { Box, Center, keyframes, SystemStyleObject } from '@chakra-ui/react'
import { colors } from '@retorio/ui'
import { forwardRef, PropsWithChildren } from 'react'

const pulseAnimation = keyframes`
  0%, 30% {
    transform: scale(1);
  }

  70%, 100% {
    transform: scale(0.6); 
  }
`

export const RemainingTime = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ sx?: SystemStyleObject }>
>(({ sx, children }, ref) => (
  <Box ref={ref} borderColor={colors.red[300]} sx={sx}>
    <Center
      bgColor="rgb(255, 255, 255, 0.7)"
      borderRadius="full"
      py={1}
      gap={2}
      w="100px"
      color={colors.gray[600]}
      shadow="md"
      justifyContent="flex-start"
      px={3}
    >
      <Center
        minWidth="20px"
        height="20px"
        borderRadius="full"
        border="2px solid"
        borderColor={colors.indicator['red-dark']}
      >
        <Box
          animation={`${pulseAnimation} 1.5s linear infinite alternate`}
          width="12px"
          height="12px"
          borderRadius="full"
          bg={colors.indicator.red}
        />
      </Center>

      {children}
    </Center>
  </Box>
))
